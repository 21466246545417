<template>
    <a-card>
        <a-page-header
            :title='"设置监测链接"'
        />
        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-form-model-item label="广告主">
                {{ form.advertiserName || '-' }}
            </a-form-model-item>
            <a-form-model-item label="广告平台">
                {{ form.adPlatformName || '-' }}
            </a-form-model-item>
            <a-form-model-item :label="item.monitorName" v-for="(item, index) in form.monitorUrlInfoList">
                <a-input
                    v-model="item.monitorUrl"
                    style="width: 500px;"
                />
                <span class="pl-20">请输入https://链接</span>
            </a-form-model-item>

            <!-- <a-form-model-item label="点击">
                <a-input
                    v-model="form.name2"
                    style="width: 500px;"
                />
                <span class="pl-20">请输入https://链接</span>
            </a-form-model-item>
            <a-form-model-item label="视频有效播报">
                <a-input
                    v-model="form.name12"
                    style="width: 500px;"
                />
                <span class="pl-20">请输入https://链接</span>
            </a-form-model-item>
            <a-form-model-item label="视频播完">
                <a-input
                    v-model="form.name1"
                    style="width: 500px;"
                />
                <span class="pl-20">请输入https://链接</span>
            </a-form-model-item>
            <a-form-model-item label="视频播放">
                <a-input
                    v-model="form.name2"
                    style="width: 500px;"
                />
                <span class="pl-20">请输入https://链接</span>
            </a-form-model-item> -->
            <a-form-model-item label=" " :colon="false">
                <a-space>
                    <base-button
                        :title="'取消'"
                        @onClickBtn="handleBack"
                    ></base-button>
                    <base-button
                        :type="'primary'"
                        :title="'提交'"
                        :loading="isSubmit"
                        :disabled="isSubmit"
                        @onClickBtn="handleSubmit"
                    ></base-button>
                </a-space>
            </a-form-model-item>
        </a-form-model>
    </a-card>
</template>

<script>
const monitorUrlInfoList = [
    { monitorName: '展示', monitorUrl: '', sort: 1 },
    { monitorName: '点击', monitorUrl: '', sort: 2 },
    { monitorName: '视频有效播报', monitorUrl: '', sort: 3 },
    { monitorName: '视频播完', monitorUrl: '', sort: 4 },
    { monitorName: '视频播放', monitorUrl: '', sort: 5 },
]
export default {
    data() {
        return {
            adPlatformId: this.$route.query.adPlatformId,
            advertiserId: this.$route.query.advertiserId,
            layout: {
                labelCol: { span: 6 },
                wrapperCol: { span: 18 }
            },
            form: {
                advertiserName: '',
                adPlatformName: '',
                monitorUrlInfoList: monitorUrlInfoList
            },
            isSubmit: false
        }
    },
    created() {
        this.getDetail()
    },
    methods: {
        getDetail() {
            let params = {
                adPlatformId: this.adPlatformId,
                advertiserId: this.advertiserId
            }
            this.$api.core.pphtManage.getAdvertiserMonitorDetail(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    let _data = res.data
                    _data.monitorUrlInfoList = _data.monitorUrlInfoList && _data.monitorUrlInfoList.length > 0 ? _data.monitorUrlInfoList : monitorUrlInfoList

                    Object.assign(this.form, _data)
                } else {
                    this.$message.error(`获取详情失败，${res.message}`)
                }
            })
        },
        handleBack() {
            let that = this
            this.$confirm({
                content: h => <div><span style="color: red; display: block;">表单数据还未保存</span>离开数据将会丢失，是否确认离开？
                </div>,
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$router.push({
                        path: '/pphtManage/monitorManage'
                    })
                }
            })
        },
        handleSubmit() {
            let that = this
            that.isSubmit = false

            this.$confirm({
                title: '确认设置监测链接？',
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$api.core.pphtManage.saveOrUpdateMonitorUrl(that.form).then((res) => {
                        that.isSubmit = true
                        if(res.code == 200) {
                            that.$message.success('设置成功')
                            that.$router.push({
                                path: '/pphtManage/monitorManage'
                            })
                        } else {
                            that.$message.error('设置失败')
                        }
                    })
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
.pl-20 {
    padding-left: 20px;
}
</style>